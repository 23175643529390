import React from 'react'

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M19.679 15.552l.048.794-.803-.097c-2.922-.373-5.475-1.637-7.643-3.761l-1.06-1.054-.273.778c-.578 1.735-.208 3.567.996 4.799.642.68.498.778-.61.373-.386-.13-.723-.227-.755-.179-.112.114.273 1.589.578 2.172.418.81 1.269 1.605 2.2 2.075l.787.373-.932.016c-.899 0-.93.017-.835.357.322 1.054 1.59 2.172 3.003 2.658l.996.34-.867.52a9.04 9.04 0 01-4.304 1.2c-.722.015-1.316.08-1.316.129 0 .162 1.959 1.07 3.099 1.426 3.42 1.054 7.482.6 10.533-1.2 2.168-1.28 4.335-3.825 5.347-6.289.546-1.313 1.092-3.712 1.092-4.863 0-.746.048-.843.947-1.735.53-.518 1.028-1.086 1.124-1.248.16-.308.145-.308-.674-.032-1.365.486-1.558.421-.883-.308.497-.519 1.091-1.459 1.091-1.735 0-.048-.24.033-.514.179-.288.162-.93.405-1.412.55l-.868.277-.786-.536c-.434-.291-1.044-.616-1.365-.713-.82-.227-2.072-.194-2.81.065-2.007.73-3.276 2.61-3.131 4.669z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent

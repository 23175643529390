import { Component } from 'react'
import Link from 'next/link'
import {
  createUseStyles,
} from 'react-jss'
import classNames from 'classnames'
import LogoWhite from './LogoWhite'
import TwitterIcon from './icons/TwitterIcon'
import FacebookIcon from './icons/FacebookIcon'
import InstagramIcon from './icons/InstagramIcon'
import EmailIcon from './icons/EmailIcon'

const useStyles = createUseStyles(theme => ({
  container: {
    background: '#444444',
  },
  content: {
    maxWidth: 1200,
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
    paddingLeft: '8%',
    paddingRight: '8%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '4%',
      paddingRight: '4%',
    },
  },
  flexItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing.unit * -1,
      marginBottom: theme.spacing.unit * -1,
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  },
  logoSectionMobile: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  },
  logoSectionAppDownload: {
    flexDirection: 'column',
    marginLeft: theme.spacing.unit * 4,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  },
  logo: {
    height: 49,
    width: 161,
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 99,
    },
  },
  logoAppDownload: {
    paddingBottom: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  topLinks: {
    fontSize: 14,
    lineHeight: '21px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: '#FFFFFF',
    marginLeft: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      width: 156,
      marginLeft: 'auto',
    },
  },
  topLinksAppDownload: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: theme.spacing.unit * 3,
    },
  },
  topLink: {
    wordBreak: 'keep-all',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  contactLinks: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  contactLinksAppDownload: {
    alignItems: 'center',
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit * 3,
    },
  },
  snsIcon: {
    height: 40,
    width: 40,
  },
  emailLink: {
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  email: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #FFFFFF',
    borderRadius: 2,
    height: 40,
    marginLeft: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  emailIcon: {
    width: 22.88,
    height: 16.6,
    marginRight: theme.spacing.unit,
  },
  emailText: {
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
  },
  tag: {
    fontSize: 14,
    lineHeight: '21px',
    textAlign: 'center',
    color: '#FFFFFF',
    marginTop: theme.spacing.unit * 2,
  },
}))

const Footer = props => {
  const classes = useStyles()


  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classNames({
          [classes.flexItems]: props.showAppDownload,
        })}>
          <div className={classNames(classes.topSection, {
            [classes.topSectionAppDownload]: props.showAppDownload,
          })}>
            <div className={classNames(classes.logoSectionMobile, {
              [classes.logoSectionAppDownload]: props.showAppDownload,
            })}>
              <LogoWhite className={classNames(classes.logo, {
                [classes.logoAppDownload]: props.showAppDownload,
              })} />
              <div className={classNames(classes.topLinks, {
                [classes.topLinksAppDownload]: props.showAppDownload,
              })}>
                <div className={classes.topLink}>
                  <Link
                    href="/about" //privacy
                    className={classes.link}>
                    プライバシーポリシー
                  </Link>
                </div>
                |
                <div className={classes.topLink}>
                  <Link
                    href="/about" //kiyaku
                    className={classes.link}>
                    利用規約
                  </Link>
                </div>
                |
                <div className={classes.topLink}>
                  <Link
                    href="/about"
                    className={classes.link}>
                    会社概要
                  </Link>
                </div>
              </div>
            </div>
            <div className={classes.sectionMobile}>
              <div className={classNames(classes.contactLinks, {
                [classes.contactLinksAppDownload]: props.showAppDownload,
              })}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/@Ryoma_Taniai">
                  <TwitterIcon className={classes.snsIcon} />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/POTLUCK.SHIBUYA">
                  <FacebookIcon className={classes.snsIcon} />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/potluck.tokyo">
                  <InstagramIcon className={classes.snsIcon} />
                </a>
                <a
                  href="mailto:info@pot-luck.jp"
                  className={classes.emailLink}>
                  <div className={classes.email}>
                    <EmailIcon className={classes.emailIcon} />
                    <div className={classes.emailText}>
                      お問い合わせ
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tag}>
          ©RYM&CO. Inc.
        </div>
      </div>
    </div>
  )
}

export default Footer

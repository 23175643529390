import React from 'react'

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M25.261 21.294l.612-3.991h-3.83v-2.59c0-1.091.535-2.157 2.25-2.157h1.742V9.158s-1.58-.27-3.09-.27c-3.156 0-5.216 1.913-5.216 5.373v3.042h-3.506v3.991h3.506v9.65c.704.11 1.424.167 2.157.167.734 0 1.454-.057 2.158-.167v-9.65h3.217z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
